import React, { useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';


const FormRow = styled.div`
  @media only screen and (min-width: 600px) {
    display: flex;
  }
`;

const FormColumn = styled.div`
  width: 100%;

  .field {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .required {
    color: #d73131;
  }

  .submit-button {
    text-transform: uppercase;
    color: #fff;
    padding: 0.5rem 3rem;
    display: block;
    border: 1px solid #bd8b13;
    border-radius: 3px;
    background-color: #bd8b13;
    font-size: 0.9rem;
    font-weight: 700;
    display: inline-block;
    cursor: pointer;

    -webkit-transition: background-color 0.2s linear, border-color 0.2s linear,
      color 0.2s linear;
    transition: background-color 0.2s linear, border-color 0.2s linear,
      color 0.2s linear;

    &:focus {
      box-shadow: 0 0 0 2px #ffffff, 0 0 3px 5px #3a97f9;
      outline: 2px dotted transparent;
      outline-offset: 2px;
    }

    &:hover {
      border-color: #a67b10;
      background-color: #a67b10;
    }

    &:active {
      background-color: #d9ae43;
    }

    @media only screen and (max-width: 599px) {
      width: 100%;
      text-align: center;
    }
  }

  input:not([type='radio']),
  textarea,
  select {
    padding: 0 12px;
    height: 2.25rem;
    line-height: 2.25rem;
    width: 100%;
    border: thin solid #f0ede4;
  }

  select {
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    background-color: white;
    

    background-repeat: no-repeat;
    padding-right: 20px;
    background-size: 15px;
    padding-right: 25px;
    background-position: right 5px center;

    .default {
      color: green;
    }
  }

  textarea {
    resize: none;
    height: 7.5rem;
  }

  .radios {
    label {
      margin-right: 1.5rem;
      input {
        margin-right: 0.5rem;
        position: relative;
        top: -1px;
      }
    }
  }

  @media only screen and (min-width: 600px) {
    &:first-child {
      padding-right: 0.625rem;
    }

    &:last-child {
      padding-left: 0.625rem;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    textarea {
      height: 10.95rem;
      &.available-units {
        height: 3rem;
      }
    }
  }
`;


function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  render() {
    

  
    

    

    return (
      <section className="section">
        <div className="container">
          <div className="content">
            <h2 style={{ marginTop: 0, color: '#181816' }}>
              Interested Buyers
            </h2>
            
          </div>
        </div>
      </section>
    );
  }
}
